@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	scroll-behavior: smooth;
    
}

svg {
	display: inline-block;
}

@font-face {
    font-family: 'Averta';
    src: local('Averta Regular'), local('Averta-Regular'),
        url('./fonts/subset-Averta-Regular.woff2') format('woff2'),
        url('./fonts/subset-Averta-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: local('Averta Regular Italic'), local('Averta-RegularItalic'),
        url('./fonts/subset-Averta-RegularItalic.woff2') format('woff2'),
        url('./fonts/subset-Averta-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: local('Averta Semibold'), local('Averta-Semibold'),
        url('./fonts/subset-Averta-Semibold.woff2') format('woff2'),
        url('./fonts/subset-Averta-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: local('Averta Semibold Italic'), local('Averta-SemiboldItalic'),
        url('./fonts/subset-Averta-SemiboldItalic.woff2') format('woff2'),
        url('./fonts/subset-Averta-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: local('Averta Bold'), local('Averta-Bold'),
        url('./fonts/subset-Averta-Bold.woff2') format('woff2'),
        url('./fonts/subset-Averta-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: local('Averta Bold Italic'), local('Averta-BoldItalic'),
        url('./fonts/subset-Averta-BoldItalic.woff2') format('woff2'),
        url('./fonts/subset-Averta-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

/* Pretend Averta is Galano for Bright Components */

@font-face {
    font-family: 'galano';
    src: local('Averta Regular'), local('Averta-Regular'),
        url('./fonts/subset-Averta-Regular.woff2') format('woff2'),
        url('./fonts/subset-Averta-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'galano';
    src: local('Averta Bold'), local('Averta-Bold'),
        url('./fonts/subset-Averta-Bold.woff2') format('woff2'),
        url('./fonts/subset-Averta-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'galano-bold';
    src: local('Averta Bold'), local('Averta-Bold'),
        url('./fonts/subset-Averta-Bold.woff2') format('woff2'),
        url('./fonts/subset-Averta-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

.react-datepicker__aria-live {
    display: none;
  }
